/**
 * Format a number as a currency
 * @param value - The number to format
 * @param decimals - The number of decimals to display
 * @returns The formatted currency
 * @example
 * formatCurrency(1234) // "1 234 €"
 * formatCurrency(1234.5) // "1 234,50 €"
 * formatCurrency(1234.56) // "1 234,56 €"
 * formatCurrency(1234.56, 0) // "1 235 €"
 * formatCurrency(1234.56, 3) // "1 234,560 €"
 */
export const formatCurrency = (value: number | string | undefined | null, decimals: number = 2) => {
  if (value === null || value === undefined) {
    return "";
  }

  const formatter = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(Number(value));
};

/**
 * Format a number as a percentage
 * @param value - The number to format
 * @param decimals - The number of decimals to display
 * @returns The formatted percentage
 * @example
 * formatPercentage(0.3) // "30 %"
 * formatPercentage(0.32) // "32 %"
 * formatPercentage(0.1234) // "12,34 %"
 * formatPercentage(0.1234, 3) // "12,340 %"
 */
export const formatPercentage = (
  value: number | string | undefined | null,
  decimals: number = 2
) => {
  if (value === null || value === undefined) {
    return "";
  }

  const formatter = new Intl.NumberFormat("fr", {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(Number(value)).replace(",00", "");
};
